<template>
  <a-result status="404" title="404" sub-title="页面不存在">
    <template #extra>
      <a-button type="primary" @click="homeBack">
        返回上一级
      </a-button>
    </template>
  </a-result>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods:{
    homeBack(){
      this.$router.push({path:"/"});
    }
  }
};
</script>